<!--
 * @Author: John
 * @Date: 2022-02-05 12:59:43
 * @LastEditTime: 2022-04-29 15:59:55
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\views\register.vue
 * @Copyright John
-->
<template>
    <div id="wrap">
        <div class="logo-box">
            <img src="@/assets/img/title_logo2.png" alt="">
        </div>
        <el-card class="box-card login-panel">
            <div slot="header" class="clearfix">
                <h2 style="text-align: center">欢迎注册{{$root.appName}}</h2>
            </div>
            <el-form ref="registerForm" :model="formData" :rules="formRules" status-icon label-width="100px">
                <el-form-item label="昵称：" prop="nickname">
                    <el-input v-model="formData.nickname" prefix-icon="el-icon-user" clearable></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="gender">
                    <el-select v-model="formData.gender" placeholder="请选择">
                        <el-option
                            v-for="item in genderOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电话：" prop="phone">
                    <el-input v-model="formData.phone" prefix-icon="el-icon-phone"></el-input>
                </el-form-item>
                <el-form-item label="用户名：" prop="username">
                    <el-input v-model="formData.username" clearable prefix-icon="el-icon-user-solid"></el-input>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="重复密码：" prop="rePassword">
                    <el-input v-model="formData.rePassword" prefix-icon="el-icon-lock" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="邮箱：" prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-message" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('registerForm')">注册</el-button>
                    <el-button @click="resetForm('registerForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="footer-bar">
            <footer-bar />
        </div>
    </div>
</template>
<script>
import api from '../api/req_api'
import sha256 from 'crypto-js/sha256'
import footerBar from '../components/footer-bar.vue'
export default {
    components: {
        footerBar
    },
    data(){
        let validateUsername = (rule, value, callback) => {
            let usernameReg = /\w{6,}/g;
            if(!usernameReg.test(value)) {
                callback(new Error('用户名只能包含英文字母、数字和下划线'));
                return;
            }
            callback();
        };
        let validateRePassword = (rule, value, callback) => {
            if(value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
                return;
            }
            callback();
        };
        let validateEmail = (rule, value, callback) => {
            let emailReg = /\w+@\w+\.(com|co|cn|org)/g;
            if(!emailReg.test(value)) {
                callback(new Error('请输入合法的邮箱地址!'));
                return;
            }
            callback();
        };
        return {
            genderOpts: [
                {label: '男', value: '男'},
                {label: '女', value: '女'},
                {label: '保密', value: '保密'},
            ],
            formData: {
                username: '',
                password: '',
                rePassword: '',
                nickname: '',
                email: '',
                gender: ''
            },
            formRules: {
                username: [
                    {required: true, message: '用户名是必填项!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                    { validator: validateUsername, trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '密码是必填项!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'}
                ],
                rePassword: [
                    {required: true, message: '请输入重复密码!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                    {validator: validateRePassword, trigger: 'blur'}
                ],
                nickname: [
                    {required: true, message: '昵称是必填项!', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: '电子邮箱是必填项!', trigger: 'blur'},
                    {validator: validateEmail, trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        onSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.password = sha256(this.formData.password).toString();    // 对密码加密
                    api.register(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message({message: "注册成功!", type: 'success'});
                                // location.href = '/main';   // 使用这种方式会造成浏览器刷新，vuex中的数据会丢失
                                this.$router.push('/login');
                            } 
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName){
            this.$refs[formName].resetFields();
        }
    }
}
</script>